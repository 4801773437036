import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGetQuestionsQuery, useDeleteCategoryMutation, useCreateQuestionsMutation } from "state/api";
import { Header } from "components";


const Questions = () => {
  const { data, isLoading ,refetch } = useGetQuestionsQuery();
  const [open, setOpen] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteCategory, { isLoading: LoadingDelete }] = useDeleteCategoryMutation();
  const [createQuestion, { isLoading: LoadingCreate }] = useCreateQuestionsMutation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);




  const [categoryName, setCategoryName] = useState("");
  const [imageName, setImageName] = useState('');
  const [questionType, setQuestionType] = useState('a+b');
  const [questionCount, setQuestionCount] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    if (e.target.files && e.target.files[0]) {
      setImageName(e.target.files[0].name);
    }
  };

  const handleDelete = (id) => {
    setSelectedCategoryId(id);
    setOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteCategory(selectedCategoryId);
      toast.success('Category Deleted!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error deleting category:", error);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateDialogOpen = () => {
    setOpenCreateDialog(true);
  };

  const handleCreateDialogClose = () => {
    setOpenCreateDialog(false);
  };

  const handleCreateCategory = async () => {
    try {
      if (selectedImage == null) {
        return false;
      }
      await createQuestion({ name: categoryName, image: selectedImage });
      toast.success('Category created successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setOpenCreateDialog(false);
      setCategoryName("");
      setSelectedImage(null);
      setImageName("");
    } catch (error) {
      console.error("Error creating category:", error);
    }
    setOpenCreateDialog(false);
  };

  const handleCreateQuestion = () => {
    setOpenQuestionDialog(true);
  };

  const handleQuestionDialogClose = () => {
    setOpenQuestionDialog(false);
  };

  const handleCreateQuestions = async () => {
    console.log("Creating questions:", questionType, questionCount);
    await createQuestion({ type: questionType, count: questionCount });
      toast.success('Question created successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      refetch();
    setOpenQuestionDialog(false);
  };
  const paginatedData = data ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];


  return (
    <Box m="1.5rem 2.5rem">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header title="Questions" subtitle="See your list of questions." />
      <Box display="flex" justifyContent="flex-end" m="1.5rem 2.5rem">
        <Button variant="contained" color="primary" onClick={handleCreateQuestion}>
          Create New Questions
        </Button>
      </Box>
      {data || !isLoading ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Question Type</TableCell>
                  <TableCell>Options</TableCell>
                  <TableCell>Correct Ans</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map(({ _id, title, questionFormat,optionA,optionB,optionC,optionD,correctOption }, index) => (
                  <TableRow key={_id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{title}</TableCell>
                    <TableCell>{questionFormat}</TableCell>
                    <TableCell>  <Typography textAlign="left">
                    {optionA} , {optionB} ,  {optionC} ,  {optionD}
        </Typography>
        
        
        </TableCell>
                    <TableCell>{correctOption}</TableCell>
                  
                    <TableCell>
                      <IconButton onClick={() => handleDelete(_id)} color="secondary">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Typography variant="h5" mt="20%" textAlign="center">
          Loading...
        </Typography>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              '&:hover': {
                color: 'grey',
                backgroundColor: 'white',
                border: 'red',
              },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={confirmDelete}
            color="primary"
            autoFocus
            loading={LoadingDelete}
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                color: 'red',
                backgroundColor: 'white',
                border: 'red',
              },
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCreateDialog}
        onClose={handleCreateDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Category Name"
            fullWidth
            variant="outlined"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <input
            accept="image/*"
            id="image"
            type="file"
            name="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <label htmlFor="image">
            <Button
              variant="outlined"
              component="span"
              style={{ color: 'green', borderColor: 'green' }}
              startIcon={<IconButton color="primary" aria-label="upload picture" component="span"></IconButton>}
            >
              Select Image
            </Button>
          </label>
          {imageName && <Typography variant="body1">{imageName}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreateDialogClose}
            color="primary"
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              '&:hover': {
                color: 'white',
                backgroundColor: 'grey',
                border: 'green',
              },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleCreateCategory}
            color="primary"
            loading={LoadingCreate}
            sx={{
              backgroundColor: 'green',
              color: 'white',
              '&:hover': {
                color: 'green',
                backgroundColor: 'white',
                border: 'green',
              },
            }}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openQuestionDialog}
        onClose={handleQuestionDialogClose}
        aria-labelledby="question-dialog-title"
      >
        <DialogTitle id="question-dialog-title">Create Questions</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="count"
            name="count"
            label="Number of Questions"
            fullWidth
            variant="outlined"
            value={questionCount}
            onChange={(e) => setQuestionCount(Number(e.target.value))}
            type="number"
          />
          <Select
            labelId="type-label"
            id="type"
            value={questionType}
            onChange={(e) => setQuestionType(e.target.value)}
            fullWidth
          >
            <MenuItem value="a+b">a+b</MenuItem>
            <MenuItem value="aa+b">aa+b</MenuItem>
            <MenuItem value="a-b">a-b</MenuItem>
            <MenuItem value="aa-b">aa-b</MenuItem>
            <MenuItem value="a*b">a*b</MenuItem>
            <MenuItem value="aa*b">aa*b</MenuItem>
            <MenuItem value="a/b">a/b</MenuItem>
            <MenuItem value="aa/b">aa/b</MenuItem>
            <MenuItem value="a%b">a%b</MenuItem>
            <MenuItem value="aa%b">aa%b</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleQuestionDialogClose}
            color="primary"
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              '&:hover': {
                color: 'white',
                backgroundColor: 'grey',
                border: 'green',
              },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleCreateQuestions}
            color="primary"
            sx={{
              backgroundColor: 'green',
              color: 'white',
              '&:hover': {
                color: 'green',
                backgroundColor: 'white',
                border: 'green',
              },
            }}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Questions;
