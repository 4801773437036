import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  useTheme,

} from "@mui/material";
import {baseImageUrl} from "../utils/common";

const baseUrl = baseImageUrl;

const CategoryComponent = ({ _id, name, image, onEdit, onDelete }) => {
    const theme = useTheme();
  
    return (
      <Card
        sx={{
          backgroundImage: "none",
          backgroundColor: theme.palette.background.alt,
          borderRadius: "0.55rem",
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: 14 }}
            color={theme.palette.secondary[700]}
            gutterBottom
          >
            {name}
          </Typography>
          <Typography variant="h5" component="div">
            {name}
          </Typography>
          {image && (
            <Box
              component="img"
              sx={{ width: 100, height: 100, marginTop: "10px" }}
              alt={name}
              src={`${baseUrl}${image}`}
            />
          )}
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => onEdit(_id)}
            sx={{ color: "green", borderColor: "green" }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => onDelete(_id)}
            sx={{ color: "red", borderColor: "red" }}
          >
            Delete
          </Button>
        </CardActions>
      </Card>
    );
  };

  export default CategoryComponent;