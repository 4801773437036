import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useGetCategoryQuery, useDeleteCategoryMutation, useCreateCategoryMutation,useUpdateCategoryMutation } from "state/api";
import { Header,CategoryComponent } from "components";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';




const Categories = () => {
  const { data, isLoading } = useGetCategoryQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");  
  const [open, setOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteCategory, { isLoading: LoadingDelete }] = useDeleteCategoryMutation();
  const [createCategory, { isLoading: LoadingCreate }] = useCreateCategoryMutation();
  const [updateCategory, { isLoading: LoadingUpdate }] = useUpdateCategoryMutation();
  const [categoryName, setCategoryName] = useState("");
  const [imageName, setImageName] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const notify = () => toast("Wow so easy!");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    if (e.target.files && e.target.files[0]) {
      setImageName(e.target.files[0].name);
    }
  };

 

  const handleDelete = (id) => {
    setSelectedCategoryId(id);
    setOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteCategory(selectedCategoryId);
      console.log("Category deleted successfully!");
      toast.success('Category Deleted!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        
    } catch (error) {
      console.error("Error deleting category:", error);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const handleCreateDialogOpen = () => {
    setOpenCreateDialog(true);
  };
  const handleCreateDialogClose = () => {
    setOpenCreateDialog(false);
  };

  const handleCreateCategory = async () => {
    try {
      console.log(categoryName, selectedImage);
      if(selectedImage == null){
        return false;
      }
      await createCategory({ name: categoryName, image: selectedImage });
      console.log("Category created successfully!");
      toast.success('Category created successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      setOpenCreateDialog(false);
      setCategoryName("");
      setSelectedImage(null);
      setImageName("");
    } catch (error) {
      console.error("Error creating category:", error);
    }
    setOpenCreateDialog(false);
  };

  const handleEdit = (id) => {
    const category = data.find(cat => cat._id === id);
    if (category) {
      setEditingCategory(category);
      setCategoryName(category.name);
      setImageName(category.image);
      setEditDialogOpen(true);
    }
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditingCategory(null);
    setCategoryName("");
    setSelectedImage(null);
    setImageName("");
  };

  const handleUpdateCategory = async () => {
    try {
      await updateCategory({ _id: editingCategory._id, name: categoryName, image: selectedImage || editingCategory.image });
      console.log("Category updated successfully!");
      handleEditClose();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
/>
      <Header title="Category" subtitle="See your list of category." />
      <Box display="flex" justifyContent="flex-end" m="1.5rem 2.5rem">
        <Button variant="contained" color="primary" onClick={handleCreateDialogOpen}>
          Create Category
        </Button>
      </Box>
      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {data.map(({ _id, name, image }) => (
            <CategoryComponent
              key={_id}
              _id={_id}
              name={name}
              image={image}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
        </Box>
      ) : (
        <Typography variant="h5" mt="20%" textAlign="center">
          Loading...
        </Typography>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              '&:hover': {
                color: 'grey',
                backgroundColor: 'white',
                border: 'red',
              },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={confirmDelete}
            color="primary"
            autoFocus
            loading={LoadingDelete}
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                color: 'red',
                backgroundColor: 'white',
                border: 'red',
              },
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCreateDialog}
        onClose={handleCreateDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Category Name"
            fullWidth
            variant="outlined"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
         <input
        accept="image/*"
        id="image"
        type="file"
        name="file"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <label htmlFor="image">
        <Button
          variant="outlined"
          component="span"
          style={{ color: 'green', borderColor: 'green' }}
          startIcon={<IconButton color="primary" aria-label="upload picture" component="span"></IconButton>}
        >
          Select Image
        </Button>
      </label>
      {imageName && <Typography variant="body1">{imageName}</Typography>}

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreateDialogClose}
            color="primary"
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              '&:hover': {
                color: 'white',
                backgroundColor: 'grey',
                border: 'green',
              },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleCreateCategory}
            color="primary"
            loading={LoadingCreate}
            sx={{
              backgroundColor: 'green',
              color: 'white',
              '&:hover': {
                color: 'green',
                backgroundColor: 'white',
                border: 'green',
              },
            }}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle id="edit-dialog-title">Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="edit-name"
            name="editName"
            label="Category Name"
            fullWidth
            variant="outlined"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <input
            accept="image/*"
            id="edit-image"
            type="file"
            name="editFile"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <label htmlFor="edit-image">
            <Button
              variant="outlined"
              component="span"
              style={{ color: 'green', borderColor: 'green' }}
              startIcon={<IconButton color="primary" aria-label="upload picture" component="span"></IconButton>}
            >
              Select Image
            </Button>
          </label>
          {imageName && <Typography variant="body1">{imageName}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditClose}
            color="primary"
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              '&:hover': {
                color: 'white',
                backgroundColor: 'grey',
                border: 'green',
              },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleUpdateCategory}
            color="primary"
            loading={LoadingUpdate}
            sx={{
              backgroundColor: 'green',
              color: 'white',
              '&:hover': {
                color: 'green',
                backgroundColor: 'white',
                border: 'green',
              },
            }}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Categories;
