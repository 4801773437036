import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Card,
  CardMedia,
  CardActions,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSaveProductImageMutation ,useDeleteImageMutation } from "state/api";
import {baseImageUrl} from "../utils/common";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ImageDialog = ({ open, onClose , productId , images }) => {
  const [newImage, setNewImage] = useState(null);
  const [saveImageObject, { isLoading: LoadingSave }] = useSaveProductImageMutation();
  const [deleteImage, { isLoading: LoadingDelete }] = useDeleteImageMutation();
  
  const onDeleteImage = async (id) =>{
     await deleteImage(id);
     onClose();
     toast.success('Product Image Deleted!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
       
        });
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    
  };



  const handleAddImage = async () => {
    if (newImage) {
     // onAddImage(newImage);
      // setNewImage(null);
    const {data,error} =   await saveImageObject({ productId: productId, image: newImage });
      setNewImage(null);
      if(error){
        toast.error('Please Select Png Format image!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
         
          });
      }

      if(data){
        toast.success('Image added Successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
         
          });
          onClose();
      }
      
      
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Manage Images</DialogTitle>
      <DialogContent>
        <Box display="flex" flexWrap="wrap" gap={2}>
          {images != null && images.map((image, index) => (
            <Card key={index} sx={{ width: 150 }}>
              <CardMedia
                component="img"
                height="140"
                image={`${baseImageUrl}${image.image}`}
                alt={`Image ${index + 1}`}
              />
              <CardActions>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => onDeleteImage(image._id)}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        </Box>
        <Box mt={2}>
          <input
            accept="image/*"
            id="new-image"
            type="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <label htmlFor="new-image">
            <Button
              variant="outlined"
              component="span"
              style={{ color: "green" }}
              startIcon={<AddPhotoAlternateIcon />}
            >
              Add Image
            </Button>
          </label>
          {newImage && (
            <Box mt={2}>
              <Typography variant="body1">{newImage.name}</Typography>
              <LoadingButton
                variant="contained"
                color="primary"
                style={{ color: "green" }}
                onClick={handleAddImage}
              >
                Upload
              </LoadingButton>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageDialog;
